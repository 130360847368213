import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = { class: "label" }
const _hoisted_3 = { class: "control has-icons-left has-icons-right mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PasswordField = _resolveComponent("PasswordField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$gettext("Enter your password")), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_PasswordField, {
        password: _ctx.password,
        "onUpdate:password": _cache[0] || (_cache[0] = (x) => (_ctx.password = x)),
        ref: "creds",
        onKeyup: _withKeys(_ctx.submitPassword, ["enter"])
      }, null, 8, ["password", "onKeyup"])
    ]),
    _createElementVNode("button", {
      class: "button is-primary",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.submitPassword()))
    }, _toDisplayString(_ctx.$gettext("Send")), 1)
  ]))
}