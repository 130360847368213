import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49ce7b3b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "is-flex is-flex-direction-column is-justify-content-space-evenly" }
const _hoisted_2 = { class: "is-flex is-flex-direction-column custom-amount-input" }
const _hoisted_3 = {
  key: 0,
  class: "frame3-sub-title mb-3"
}
const _hoisted_4 = {
  key: 1,
  class: "frame3-sub-title mb-3"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "frame3-sub-title mb-3" }
const _hoisted_7 = { class: "frame3-sub-title mt-3 mb-3" }
const _hoisted_8 = { class: "is-flex" }
const _hoisted_9 = ["placeholder", "disabled"]
const _hoisted_10 = { class: "amount-currency-symbol pl-2" }
const _hoisted_11 = {
  key: 1,
  class: "notification is-danger is-light"
}
const _hoisted_12 = {
  key: 2,
  class: "notification is-danger is-light"
}
const _hoisted_13 = ["placeholder"]
const _hoisted_14 = {
  key: 4,
  class: "notification is-danger is-light mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BankAccountItem = _resolveComponent("BankAccountItem")!
  const _component_RecipientItem = _resolveComponent("RecipientItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        (_ctx.directionTransfer === 'send')
          ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(_ctx.$gettext("From")), 1))
          : (_openBlock(), _createElementBlock("h2", _hoisted_4, _toDisplayString(_ctx.$gettext("To")), 1)),
        _createVNode(_component_BankAccountItem, {
          class: "mb-4",
          account: _ctx.account,
          showSubAccounts: false
        }, {
          name: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.account.name()), 1)
          ]),
          _: 1
        }, 8, ["account"])
      ]),
      (_ctx.selectedRecipient && !_ctx.isReconversion)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$gettext("To")), 1),
            _createVNode(_component_RecipientItem, { recipient: _ctx.selectedRecipient }, null, 8, ["recipient"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.$gettext("Amount")), 1),
      _createElementVNode("div", _hoisted_8, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.amount) = $event)),
          ref: "amountRequested",
          type: "number",
          min: "0",
          class: _normalizeClass(["input is-custom", {
            'is-danger': _ctx.errors.amount || _ctx.parentErrors,
          }]),
          id: "send-amount-input",
          placeholder: _ctx.$gettext('e.g. 50'),
          onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleAmountInput())),
          disabled: _ctx.config?.amount && _ctx.directionTransfer !== 'receive'
        }, null, 42, _hoisted_9), [
          [
            _vModelText,
            _ctx.amount,
            void 0,
            { number: true }
          ]
        ]),
        _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.selectedRecipient?.curr), 1)
      ]),
      (_ctx.errors.amount)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.errors.amount), 1))
        : _createCommentVNode("", true),
      (_ctx.parentErrors)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.parentErrors), 1))
        : _createCommentVNode("", true),
      (!_ctx.isReconversion)
        ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
            key: 3,
            onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleMessageInput())),
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.message) = $event)),
            class: _normalizeClass(["custom-textarea textarea mt-5", {
          'is-danger': _ctx.errors.message,
        }]),
            placeholder: _ctx.$gettext('Add a memo (optional)')
          }, null, 42, _hoisted_13)), [
            [_vModelText, _ctx.message]
          ])
        : _createCommentVNode("", true),
      (!_ctx.isReconversion && _ctx.errors.message)
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(_ctx.errors.message), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}