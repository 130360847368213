import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f613661a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["value", "type", "placeholder"]
const _hoisted_2 = { class: "icon is-right password-right-icons" }
const _hoisted_3 = {
  key: 0,
  class: "is-small mr-2"
}
const _hoisted_4 = { class: "icon is-small is-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("input", _mergeProps({
      name: "password",
      class: "input",
      value: _ctx.password,
      type: _ctx.isPasswordVisible ? 'text' : 'password',
      placeholder: _ctx.$gettext('Password'),
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onPasswordChange && _ctx.onPasswordChange(...args)))
    }, _ctx.$attrs), null, 16, _hoisted_1),
    _createElementVNode("span", _hoisted_2, [
      (_ctx.iconRight)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
            _createVNode(_component_fa_icon, { icon: _ctx.iconRight }, null, 8, ["icon"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("span", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.togglePasswordVisibility && _ctx.togglePasswordVisibility(...args))),
        class: "is-small password-visibility"
      }, [
        _createVNode(_component_fa_icon, {
          icon: _ctx.isPasswordVisible ? 'eye' : 'eye-slash'
        }, null, 8, ["icon"])
      ])
    ]),
    _createElementVNode("span", _hoisted_4, [
      _createVNode(_component_fa_icon, { icon: 'key' })
    ])
  ], 64))
}