import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c668e72c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "signup" }
const _hoisted_2 = { class: "signup-container" }
const _hoisted_3 = { class: "card" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "mb-3" }
const _hoisted_6 = { class: "field mb-5" }
const _hoisted_7 = { class: "field mb-2" }
const _hoisted_8 = { class: "control has-icons-left is-expanded" }
const _hoisted_9 = ["placeholder"]
const _hoisted_10 = { class: "icon is-small is-left" }
const _hoisted_11 = {
  key: 0,
  class: "help is-danger mt-2",
  align: "left"
}
const _hoisted_12 = { class: "field mb-2" }
const _hoisted_13 = { class: "control has-icons-left is-expanded" }
const _hoisted_14 = ["placeholder"]
const _hoisted_15 = { class: "icon is-small is-left" }
const _hoisted_16 = {
  key: 0,
  class: "help is-danger mt-2",
  align: "left"
}
const _hoisted_17 = { class: "field mb-2" }
const _hoisted_18 = { class: "control has-icons-left is-expanded" }
const _hoisted_19 = ["placeholder"]
const _hoisted_20 = { class: "icon is-small is-left" }
const _hoisted_21 = {
  key: 0,
  class: "help is-danger mt-2",
  align: "left"
}
const _hoisted_22 = { class: "field mb-2" }
const _hoisted_23 = { class: "control has-icons-left" }
const _hoisted_24 = {
  key: 0,
  class: "help is-danger",
  align: "left"
}
const _hoisted_25 = { class: "has-text-left" }
const _hoisted_26 = { class: "field mb-2" }
const _hoisted_27 = { class: "control has-icons-left" }
const _hoisted_28 = ["placeholder"]
const _hoisted_29 = { class: "icon is-small is-left" }
const _hoisted_30 = {
  key: 0,
  class: "help is-danger mt-2"
}
const _hoisted_31 = { class: "sgnup-buttons" }
const _hoisted_32 = { class: "control has-text-centered" }
const _hoisted_33 = ["disabled"]
const _hoisted_34 = {
  key: 0,
  class: "has-text-danger has-text-centered"
}
const _hoisted_35 = { class: "signup-link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_PasswordField = _resolveComponent("PasswordField")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.$config.loginLogoUrl)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.$config.loginLogoUrl,
              class: "pt-2 pb-5"
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$gettext(
              "Please fill in all the required fields below and click on Signup to create your account."
            )), 1),
        _createElementVNode("form", {
          onSubmit: _cache[11] || (_cache[11] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("p", _hoisted_8, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                  class: _normalizeClass(["input", {
                    'is-danger': _ctx.errors?.email,
                  }]),
                  id: "email",
                  placeholder: _ctx.$gettext('Email'),
                  onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.checkEmail && _ctx.checkEmail(...args)))
                }, null, 42, _hoisted_9), [
                  [
                    _vModelText,
                    _ctx.email,
                    void 0,
                    { trim: true }
                  ]
                ]),
                _createElementVNode("span", _hoisted_10, [
                  _createVNode(_component_fa_icon, { icon: "envelope" })
                ])
              ]),
              (_ctx.errors?.email)
                ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.errors?.email), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("p", _hoisted_13, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.firstName) = $event)),
                  class: _normalizeClass(["input", {
                    'is-danger': _ctx.errors?.firstName,
                  }]),
                  id: "first-name",
                  placeholder: _ctx.$gettext('Firstname'),
                  onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.checkFirstName && _ctx.checkFirstName(...args)))
                }, null, 42, _hoisted_14), [
                  [
                    _vModelText,
                    _ctx.firstName,
                    void 0,
                    { trim: true }
                  ]
                ]),
                _createElementVNode("span", _hoisted_15, [
                  _createVNode(_component_fa_icon, { icon: "user" })
                ])
              ]),
              (_ctx.errors?.firstName)
                ? (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString(_ctx.errors?.firstName), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("p", _hoisted_18, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.lastName) = $event)),
                  class: _normalizeClass(["input", {
                    'is-danger': _ctx.errors?.lastName,
                  }]),
                  id: "last-name",
                  placeholder: _ctx.$gettext('Lastname'),
                  onInput: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.checkLastName && _ctx.checkLastName(...args)))
                }, null, 42, _hoisted_19), [
                  [
                    _vModelText,
                    _ctx.lastName,
                    void 0,
                    { trim: true }
                  ]
                ]),
                _createElementVNode("span", _hoisted_20, [
                  _createVNode(_component_fa_icon, { icon: "user" })
                ])
              ]),
              (_ctx.errors?.lastName)
                ? (_openBlock(), _createElementBlock("p", _hoisted_21, _toDisplayString(_ctx.errors?.lastName), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("p", _hoisted_23, [
                _createVNode(_component_PasswordField, {
                  password: _ctx.password,
                  "onUpdate:password": _cache[6] || (_cache[6] = (x) => (_ctx.password = x)),
                  class: _normalizeClass({
                    'is-danger': _ctx.errors?.passwordStrength,
                    'is-success':
                      !_ctx.errors?.passwordStrength && _ctx.password.length > 0,
                  }),
                  id: "password",
                  onInput: _cache[7] || (_cache[7] = ($event: any) => (_ctx.checkPasswordStrength(), _ctx.checkConfirmPassword()))
                }, null, 8, ["password", "class"])
              ]),
              (_ctx.errors?.passwordStrength && _ctx.password !== '')
                ? (_openBlock(), _createElementBlock("p", _hoisted_24, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors?.passwordStrength, (err) => {
                      return (_openBlock(), _createElementBlock("div", _hoisted_25, _toDisplayString(err), 1))
                    }), 256))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_26, [
              _createElementVNode("p", _hoisted_27, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.confirmPassword) = $event)),
                  class: _normalizeClass(["input", {
                    'is-danger': _ctx.confirmPassword && _ctx.errors?.confirmPassword,
                  }]),
                  id: "confirm-password",
                  type: "password",
                  placeholder: _ctx.$gettext('Confirm password'),
                  onInput: _cache[9] || (_cache[9] = ($event: any) => (_ctx.checkConfirmPassword()))
                }, null, 42, _hoisted_28), [
                  [_vModelText, _ctx.confirmPassword]
                ]),
                _createElementVNode("span", _hoisted_29, [
                  _createVNode(_component_fa_icon, { icon: "lock" })
                ])
              ]),
              (_ctx.confirmPassword && _ctx.errors?.confirmPassword)
                ? (_openBlock(), _createElementBlock("p", _hoisted_30, _toDisplayString(_ctx.errors?.confirmPassword), 1))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_31, [
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_32, [
                _createElementVNode("button", {
                  type: "submit",
                  class: "button is-login",
                  disabled: !_ctx.isFormValid
                }, _toDisplayString(_ctx.$gettext("Sign up")), 9, _hoisted_33)
              ])
            ])
          ]),
          _createElementVNode("div", null, [
            (_ctx.errors?.default)
              ? (_openBlock(), _createElementBlock("p", _hoisted_34, _toDisplayString(_ctx.errors?.default), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_35, [
            _createElementVNode("button", {
              onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.$router.push({ name: 'Login' }))),
              type: "button"
            }, _toDisplayString(_ctx.$gettext("Already have an account ?")), 1)
          ])
        ], 32)
      ])
    ]),
    _createVNode(_component_router_view)
  ]))
}