import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card custom-card custom-card-padding" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_PendingTopUp = _resolveComponent("PendingTopUp")!
  const _component_TransactionListRecent = _resolveComponent("TransactionListRecent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: _normalizeClass([{
        hide: !_ctx.isTransactionsLoading,
      }, "icon is-small is-default is-pulled-right is-rounded refresh"])
    }, [
      _createVNode(_component_fa_icon, {
        class: _normalizeClass({ refreshing: _ctx.isTransactionsLoading }),
        icon: "sync"
      }, null, 8, ["class"])
    ], 2),
    _createVNode(_component_PendingTopUp, {
      account: _ctx.account,
      refreshToggle: _ctx.subRefreshToggle,
      onTriggerTransactionRefresh: _ctx.trigger,
      onRefreshTransaction: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('refreshTransaction')))
    }, null, 8, ["account", "refreshToggle", "onTriggerTransactionRefresh"]),
    _createVNode(_component_TransactionListRecent, {
      account: _ctx.account,
      refreshToggle: _ctx.subRefreshToggle,
      onTriggerTransactionRefresh: _ctx.trigger
    }, null, 8, ["account", "refreshToggle", "onTriggerTransactionRefresh"])
  ]))
}