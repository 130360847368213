import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2189eb39"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main" }
const _hoisted_2 = { class: "container mt-5" }
const _hoisted_3 = { class: "columns is-tablet" }
const _hoisted_4 = { class: "column" }
const _hoisted_5 = { class: "accounts card custom-card custom-card-padding" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 0,
  class: "notification is-danger is-light"
}
const _hoisted_8 = { class: "mb-4" }
const _hoisted_9 = {
  key: 1,
  class: "notification is-default"
}
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { class: "custom-card-title" }
const _hoisted_12 = { class: "table is-striped is-fullwidth" }
const _hoisted_13 = { class: "row-user-header" }
const _hoisted_14 = { class: "has-text-right row-validate-header" }
const _hoisted_15 = { class: "row-user" }
const _hoisted_16 = { class: "has-text-right" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = ["onClick"]
const _hoisted_19 = {
  key: 2,
  class: "transactions-loader-container is-pulled-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_loading, {
              active: _ctx.isLoading,
              "onUpdate:active": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isLoading) = $event)),
              "can-cancel": false,
              "is-full-page": false
            }, null, 8, ["active"]),
            (!_ctx.isLoading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  (_ctx.hasLoadingError)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$gettext(
                      "An unexpected issue occurred while loading the " +
                        "pending account list. Sorry for the inconvenience."
                    )), 1),
                        _createElementVNode("p", null, _toDisplayString(_ctx.$gettext(
                      "You can try to refresh the page, if the issue " +
                        "persists, you may want to contact your " +
                        "administrator"
                    )), 1)
                      ]))
                    : (_ctx.pendingUserAccounts.length === 0)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.$gettext("No account pending for approval")), 1))
                      : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _createElementVNode("h2", _hoisted_11, _toDisplayString(_ctx.$gettext("Accounts waiting for approval")), 1),
                          _createElementVNode("table", _hoisted_12, [
                            _createElementVNode("thead", null, [
                              _createElementVNode("tr", null, [
                                _createElementVNode("th", _hoisted_13, _toDisplayString(_ctx.$gettext("User")), 1),
                                _createElementVNode("th", _hoisted_14, _toDisplayString(_ctx.$gettext("Action")), 1)
                              ])
                            ]),
                            _createElementVNode("tbody", null, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pendingUserAccounts, (account) => {
                                return (_openBlock(), _createElementBlock("tr", {
                                  key: account.id
                                }, [
                                  _createElementVNode("td", _hoisted_15, _toDisplayString(account.name) + " " + _toDisplayString(account.markBackend
                            ? `(via ${account.backendId})`
                            : ""), 1),
                                  _createElementVNode("td", _hoisted_16, [
                                    (
                            _ctx.selectedItem !== account || !_ctx.isWaitingForValidation
                          )
                                      ? (_openBlock(), _createElementBlock("a", {
                                          key: 0,
                                          class: "button is-primary custom-button custom-inverted is-small is-pulled-right ml-2 mb-1",
                                          id: "discard",
                                          onClick: ($event: any) => (_ctx.discardUserAccount(account))
                                        }, _toDisplayString(_ctx.$gettext("Discard")), 9, _hoisted_17))
                                      : _createCommentVNode("", true),
                                    (
                            _ctx.selectedItem !== account || !_ctx.isWaitingForValidation
                          )
                                      ? (_openBlock(), _createElementBlock("a", {
                                          key: 1,
                                          class: "button is-primary custom-button custom-inverted is-small is-pulled-right",
                                          onClick: ($event: any) => (_ctx.validateUserAccount(account))
                                        }, _toDisplayString(_ctx.$gettext("Approve")), 9, _hoisted_18))
                                      : (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                          _createVNode(_component_loading, {
                                            active: _ctx.isWaitingForValidation,
                                            "onUpdate:active": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isWaitingForValidation) = $event)),
                                            "can-cancel": false,
                                            "is-full-page": false,
                                            width: 30,
                                            height: 30
                                          }, null, 8, ["active"])
                                        ]))
                                  ])
                                ]))
                              }), 128))
                            ])
                          ])
                        ]))
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ]))
}