import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = { class: "label" }
const _hoisted_3 = { class: "control has-icons-left has-icons-right" }
const _hoisted_4 = {
  key: 0,
  class: "help is-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PasswordField = _resolveComponent("PasswordField")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$gettext("PIN code")), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_PasswordField, {
          password: _ctx.pin,
          "onUpdate:password": _cache[0] || (_cache[0] = (x) => (_ctx.pin = x)),
          onKeypress: _ctx.onlyNumbers,
          placeholder: _ctx.$gettext('PIN code'),
          pattern: "[0-9]{4}",
          inputmode: "numeric",
          maxlength: "4",
          onInput: _ctx.isPinValid,
          ref: "creds",
          autocomplete: "new-password"
        }, null, 8, ["password", "onKeypress", "placeholder", "onInput"])
      ])
    ]),
    (this.errors.pinSize)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(this.errors.pinSize), 1))
      : _createCommentVNode("", true)
  ]))
}